import DraggableBlock from '../editor/DraggableBlock.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Table from '@tiptap/extension-table'
const customTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      tableStyle: {
        default: null,
        parseHTML: element => element.getAttribute('data-style') || null,
        renderHTML: attributes => {
          return attributes.tableStyle ? { 'data-style': attributes.tableStyle, style: attributes.tableStyle } : {}
        }
      }
    }
  },
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  }
})
export default customTable
