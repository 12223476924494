<template>
  <div class="mx-auto flex gap-5 min-h-full">
    <div v-if="!permissions.read" class="w-full">
      <fw-message class="mx-auto w-full max-w-3xl mt-5" type="error">
        You don't have permission to view this page
      </fw-message>
    </div>
    <div v-else-if="loading" class="flex flex-1 flex-col gap-1 min-h-full">
      <div
        v-if="version && originalVersion"
        class="flex gap-5 text-gray-500 font-semibold text-xs sticky top-0 z-10 bg-gray-50 bg-opacity-70 min-h-full"
      >
        <div class="border-r border-gray-200 pr-5 flex-1 min-w-0 pb-3 pt-4 flex flex-col gap-4">
          <div class="flex items-center gap-5">
            <div class="border cursor-pointer border-gray-400 rounded-md flex gap-4 px-2 py-1">
              <div>ORIGINAL {{ originalVersion.language }}</div>
              <span class="text-gray-400">loading</span>
            </div>
            <div class="text-gray-400">{{ languageLabel(originalVersion.language) }}</div>
          </div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
        </div>
        <div class="w-3/5 text-right flex flex-col gap-4">
          <div class="flex gap-4 pt-4 pl-px">
            <div class="flex flex-1 items-center gap-5">
              <div class="border border-gray-400 rounded-md flex gap-4 px-2 py-1">
                <div class="flex items-center gap-1"><fw-icon-branch class="w-4 h-4" />{{ version.language }}</div>
                <span class="text-gray-400">loading</span>
              </div>
              <div class="text-gray-400">{{ languageLabel(version.language) }}</div>
            </div>
            <div class="items-center gap-5 hidden">
              {{ $t('progress') }}
              <div>--</div>
            </div>
          </div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
          <div class="h-20 w-full bg-gray-200 animate-pulse"></div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-1 flex-col gap-1">
      <div :key="versionKey" class="min-h-full flex flex-col">
        <div
          v-if="version && originalVersion"
          class="flex gap-5 text-gray-500 font-semibold text-xs sticky top-0 z-10 bg-gray-50 bg-opacity-70 backdrop-blur"
        >
          <div class="border-r border-gray-200 pr-5 flex-1 min-w-0 pb-3 pt-4">
            <div class="flex items-center gap-5">
              <div
                class="border cursor-pointer border-gray-400 rounded-md flex gap-4 px-2 py-1"
                @click="openOriginal()"
              >
                <div>ORIGINAL {{ originalVersion.language }}</div>
                <span class="text-gray-400">v.1</span>
              </div>
              <div class="text-gray-400">{{ languageLabel(originalVersion.language) }}</div>
            </div>
          </div>
          <div class="w-3/5 text-right flex gap-4 pb-3 pt-4 pl-px">
            <div class="flex flex-1 items-center gap-5">
              <div v-if="translatedOptions.length == 0" class="border border-gray-400 rounded-md flex gap-4 px-2 py-1">
                <div class="flex items-center gap-1"><fw-icon-branch class="w-4 h-4" />{{ version.language }}</div>
                <span v-if="version.state == 'draft'" class="text-yellow-700">{{ $t('in_edition') }}</span>
                <span v-else class="text-gray-500">{{ version.state }}</span>
              </div>
              <b-dropdown v-else aria-role="list" :scrollable="true" :max-height="270">
                <template #trigger="{}">
                  <div class="border border-gray-400 rounded-md flex gap-4 px-2 py-1 cursor-pointer">
                    <div class="flex items-center gap-1"><fw-icon-branch class="w-4 h-4" />{{ version.language }}</div>
                    <span v-if="version.state == 'draft'" class="text-yellow-700">{{ $t('in_edition') }}</span>
                    <span v-else class="text-gray-500">{{ version.state }}</span>
                    <fw-icon-chevron-down class="w-4 h-4" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(option, o) in translatedOptions"
                  :key="option.key"
                  aria-role="listitem"
                  has-link
                  :focusable="false"
                  class="font-medium flex w-64 items-center text-sm cursor-pointer px-1.5 py-1.5 gap-2 hover:bg-gray-100"
                  :class="{
                    'border-b border-gray-100': o != translatedOptions.length - 1
                  }"
                  @click="$emit('change-version', option)"
                >
                  <fw-icon-branch class="h-4 w-4" />
                  <div class="flex-shrink-0">{{ option.language }}</div>
                  <div class="text-gray-400 flex-1 line-clamp-1 whitespace-nowrap text-left">
                    {{ languageLabel(option.language) }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <div class="text-gray-400">{{ languageLabel(version.language) }}</div>
            </div>
            <div class="items-center gap-5 hidden">
              {{ $t('progress') }}
              <div>{{ progress }}%</div>
            </div>
            <fw-button
              v-if="showEditButton && !editable"
              type="xlight"
              size="xs"
              icon="edit"
              @click.native="$emit('edit')"
            >
              {{ $t('edit') }}
            </fw-button>
          </div>
        </div>
        <div v-if="providerTarget != null && version && content != null && content.length > 0" class="scroller">
          <TranslationTextBlock
            v-for="(item, index) in content"
            :key="'block_' + (item && item.attrs && item.attrs.id ? item.attrs.id : index)"
            :ref="'translation_blocks'"
            :target-provider="providerTarget"
            :segment-number="index"
            :is-title="item.type == 'title'"
            :segment-type="item.type + (item.type == 'heading' ? '-' + item.attrs.level : '')"
            :selected="activeBlock && activeBlock.segmentNumber == index"
            :segment-id="item.attrs && item.attrs.id ? item.attrs.id : 'block_' + index"
            :original-content="item.content"
            :original-attrs="item.attrs"
            :source-language="originalVersion.language"
            :editable="permissions.write && editable"
            :page-key="pageKey"
            :version-key="versionKey"
            :user-color="currentUser.color"
            :auto-translation-options="autoTranslationOptions"
            :comments-available="
              item.type == 'title' || (typeof item.attrs != 'undefined' && typeof item.attrs.id != 'undefined')
            "
            :comments="
              item.type == 'title' ? comments['title'] : item.attrs && item.attrs.id ? comments[item.attrs.id] : []
            "
            @select-block="$emit('set-active-block', $event)"
            @new-comment="$emit('new-comment', $event)"
            @title-changed="$emit('title-changed', $event)"
          />
          <!--  @update="calculateProgress()" -->
        </div>
        <div v-if="false" class="flex-1 min-h-10 flex justify-end">
          <div class="w-3/5 h-full border-l border-gray-200" style="margin-right: 1.35rem"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
//import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
//import FormattedText from '@/fw-modules/fw-core-vue/ui/components/form/FormattedText'
import { Editor } from '@tiptap/vue-2'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Focus from '@tiptap/extension-focus'
import StarterKit from '@tiptap/starter-kit'
import Collaboration from '@tiptap/extension-collaboration'
import { isChangeOrigin } from '@tiptap/extension-collaboration'
import UniqueID from '@tiptap-pro/extension-unique-id'
//import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import CustomImage from '@/fw-modules/fw-core-vue/pages/components/editor/customImage'
import CustomVideo from '@/fw-modules/fw-core-vue/pages/components/editor/customVideo'
//import CustomHeading from '@/fw-modules/fw-core-vue/pages/components/editor/customHeading'
//import customTable from '@/fw-modules/fw-core-vue/pages/components/editor/customTable'
//import customCodeBlock from '@/fw-modules/fw-core-vue/pages/components/editor/customCodeBlock'
//import customBlockquote from '@/fw-modules/fw-core-vue/pages/components/editor/customBlockquote'
//import customBulletList from '@/fw-modules/fw-core-vue/pages/components/editor/customBulletList'
//import customOrderedList from '@/fw-modules/fw-core-vue/pages/components/editor/customOrderedList'

import { TrailingNode } from '@/fw-modules/fw-core-vue/pages/components/editor/trailingNode'
import Dropcursor from '@tiptap/extension-dropcursor'
//import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
//import customParagraph from '@/fw-modules/fw-core-vue/pages/components/editor/customParagraph'
import { HocuspocusProvider } from '@hocuspocus/provider'
//import { customTaskList, customTaskItem } from '@/fw-modules/fw-core-vue/pages/components/editor/customTaskList/'

import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ServicePages from '../../services/ServicePages'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import 'tippy.js/animations/shift-toward.css'
import TranslationTextBlock from '@/fw-modules/fw-core-vue/pages/components/blocks/TranslationTextBlock'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import locales from '@/fw-modules/fw-core-vue/utilities/locales.json'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import TextAlign from '@tiptap/extension-text-align'
import Table from '@tiptap/extension-table'
export default {
  components: {
    TranslationTextBlock
    //DynamicScroller,
    //DynamicScrollerItem,
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    users: {
      type: Object
    },
    versionKey: {
      type: String
    },
    originalKey: {
      type: String
    },
    permissions: {
      type: Object,
      required: true
    },
    usersEditing: {
      type: Object
    },
    activeBlock: {
      type: Object,
      default: null
    },
    comments: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: true
    },
    showEditButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filesToUpload: [],
      //blocks: [],
      blocksHeight: [],
      originalBlocksHeight: [],
      dirtyBlocks: new Set(),
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      loading: true,
      editor: null,
      titleEditor: null,
      providerOriginal: null,
      providerTarget: null,
      status: 'connecting',
      isEmpty: true,
      //this is the json format of the original tiptap content, it will be used to create the translation blocks
      //originalContent: null,
      versionContent: null,
      progress: 0,
      autoTranslationOptions: [],
      content: []
    }
  },
  computed: {
    translatedOptions() {
      return this.versions.filter(el => el.key != this.versionKey && el.is_default == false)
    },
    pageKey() {
      return this.page.key
    },
    currentUser() {
      return {
        name: this.me.name,
        color: this.getRandomColor()
      }
    },
    originalVersion() {
      return this.page != null && this.page.versions != null && this.page.versions.length > 0
        ? this.page.versions.filter(el => el.is_default)[0]
        : null
    },
    version() {
      return this.page != null && this.page.versions != null && this.page.versions.length > 0
        ? this.page.versions.filter(el => el.key == this.versionKey)[0]
        : null
    },
    versions() {
      return this.page != null && this.page.versions != null ? this.page.versions : []
    },
    editorKey() {
      return this.pageKey + '_' + this.version.key
    },
    me() {
      return this.$store.state.session.user
    },
    userToken() {
      return this.$store.state.session.token
    }
  },
  watch: {
    userToken() {
      this.prepareEditor()
    }
  },
  mounted() {
    this.prepareEditor()
    this.getAutoTranslationOptions()
  },
  beforeDestroy() {
    this.editor?.destroy()
    this.providerOriginal?.destroy()
    this.providerTarget?.destroy()
  },
  methods: {
    openOriginal() {
      this.$router.push({
        name: 'content-pages-viewer-with-version',
        params: { key: this.page.key, version: this.originalKey }
      })
    },
    prepareEditor() {
      if (this.providerOriginal != null) {
        console.log('already has provider')
        return
      }
      if (this.userToken) {
        let self = this
        this.providerOriginal = new HocuspocusProvider({
          url: FwEnvConfig.apiUrlHocusPocus,
          name: 'pages:' + this.pageKey + ':' + this.originalKey + ':0',
          token: this.userToken
        })
        this.providerTarget = new HocuspocusProvider({
          url: FwEnvConfig.apiUrlHocusPocus,
          name: 'pages:' + this.pageKey + ':' + this.versionKey + ':0',
          token: this.userToken,
          onAuthenticationFailed: () => {
            console.error('Target document authentication failed')
            self.permissions.write = false
            self.permissions.read = false
          },
          onSynced: () => {
            console.log('Target document synced')
            self.$emit('document-loaded')
            //create chapters, wait 2000ms
            /*setTimeout(() => {
              self.calculateProgress()
            }, 2000)*/
          }
        })
        console.log('MAIN EDITOR: ', this.pageKey + ':' + this.originalKey)
        this.editor = new Editor({
          editable: false,
          extensions: [
            TrailingNode,
            Focus.configure({
              className: 'active',
              mode: 'shallowest'
            }),
            Underline,
            StarterKit.configure({
              // The Collaboration extension comes with its own history handling
              paragraph: true,
              heading: true,
              history: false,
              dropcursor: false,
              link: false,
              codeBlock: true,
              bulletList: true,
              orderedList: true,
              blockquote: true
            }),
            Superscript,
            Subscript,
            Collaboration.configure({
              document: this.providerOriginal.document,
              field: 'body' // this.pageNumber == 0 ? 'body' : 'page.' + this.pageNumber,
            }),
            CustomImage.configure({
              HTMLAttributes: {
                class: 'editor-image'
              }
            }),
            CustomVideo,
            Dropcursor.configure({
              color: '#666666',
              width: 1.5
            }),
            UniqueID.configure({
              types: [
                'heading',
                'paragraph',
                'image',
                'taskList',
                'table',
                'codeBlock',
                'video',
                'bulletList',
                'blockquote',
                'orderedList'
              ],
              filterTransaction: transaction => !isChangeOrigin(transaction)
            }),
            Link.configure({
              openOnClick: false,
              autoLink: false
            }),
            Table.configure({
              resizable: true
            }),
            TableRow,
            TableHeader,
            TableCell,
            TextAlign.configure({
              types: ['heading', 'paragraph']
            })
          ],
          onUpdate: ({ editor }) => {
            this.loading = false
            //check if the editor is empty
            let json = editor.getJSON()
            console.log('onUpdate source document', json)
            //if (this.isEmpty) {
            //this.firstLoad = true
            if (json.content.length > 1) {
              this.isEmpty = false
            } else if (
              json.content.length == 1 &&
              (typeof json.content[0].content == 'undefined' || json.content[0].content.length == 0)
            ) {
              this.isEmpty = true
            } else {
              this.isEmpty = false
            }
            //create id for each block
            if (json.content.length > 1) {
              for (let i = 0; i < json.content.length; i++) {
                json.content[i].id = json.content[i].attrs && json.content[i].attrs.id ? json.content[i].attrs.id : i
              }
            }

            //add original title to the json
            /*json.content.unshift({
              type: 'title',
              id: 'title',
              attrs: {
                id: 'title'
              },
              content: [
                {
                  type: 'text',
                  text: this.originalVersion.title
                }
              ]
            })*/
            this.content = json.content
            //}
          }
        })
      } else {
        console.error('User token not found')
      }
    },
    async getAutoTranslationOptions() {
      let user = await ServiceAuth.getSelfUser(true)
      let options = []
      let sourceLanguageParts = this.originalVersion.language.split('-')
      let targetLanguageParts = this.version.language.toLocaleLowerCase().split('-')
      let isAutoTranslationAvailable = false
      for (const [key, value] of Object.entries(user.supported_languages)) {
        //verify if we have a source language compatible with the original language
        if (key.toLocaleLowerCase().startsWith(sourceLanguageParts[0].toLocaleLowerCase())) {
          isAutoTranslationAvailable = true
        }
        if (targetLanguageParts[0].startsWith(key)) {
          options.push({ key: key, title: value })
        }
      }
      if (isAutoTranslationAvailable) {
        this.autoTranslationOptions = options
        this.autoTranslationOptions.sort((a, b) => a.title.localeCompare(b.title))
      }
    },
    languageLabel(language) {
      return locales.find(el => el.id == language).name
    },
    getDocument(format) {
      let text = ''
      let jsonDoc = []
      let titleJson = null
      //add title
      /*let title = this.$refs.title
      let titleText = title.getText()
      if (format == 'text' && titleText.length > 0) {
        text += titleText + '\n'
      } else if (format == 'html' && titleText.length > 0) {
        text += title.getHtml()
      } else if (format == 'json' && titleText.length > 0) {
        titleJson = title.getTipTapJSON()
      }*/

      let blocks = this.$refs['translation_blocks']
      for (let i = 0; i < blocks.length; i++) {
        let block = blocks[i]
        let blockContent =
          format == 'text' ? block.getText() : format == 'html' ? block.getHtml() : block.getTipTapJSON()
        if (format == 'text' && blockContent.length > 0) {
          text += blockContent + '\n'
        } else if (format == 'html' && blockContent.length > 0) {
          text += blockContent
        } else if (format == 'json' && blockContent.content.length > 0) {
          jsonDoc.push(blockContent)
        }
      }
      if (format == 'text' || format == 'html') {
        return text
      } else if (format == 'json') {
        return {
          doc: {
            type: 'doc',
            content: jsonDoc
          },
          title: titleJson
        }
      }
    },
    getOriginalDocument(format) {
      if (format == 'text') {
        return this.originalVersion.title + '\n' + this.editor.getText()
      } else if (format == 'html') {
        return '<h1>' + this.originalVersion.title + '</h1>' + (this.editor ? this.editor.getHTML() : '')
      } else if (format == 'json') {
        return {
          doc: this.editor.getJSON(),
          title: this.originalVersion.title
        }
      }
    },
    importDocument(original, target, comments = []) {
      console.log('importDocument!!', original, target, comments)
      this.content = original.content
      this.editor.commands.setContent(original)
      this.versionContent = target
    },
    calculateProgress() {
      let total = this.content.length + 1
      if (this.isEmpty) {
        total = 1
      }
      let translated = 0
      //get title and check if it's translated
      let titleText = ''
      if (this.$refs.title) {
        titleText = this.$refs.title.getText()
      }
      //console.log('titleText', titleText)
      if (titleText.length != '') {
        translated++
      }
      for (let i = 0; i < this.content.length; i++) {
        if (this.$refs['translation_blocks'] && this.$refs['translation_blocks'][i]) {
          let blockText = this.$refs['translation_blocks'][i].getText()
          //console.log('blockText', blockText)
          if (blockText.length != '') {
            translated++
          }
        }
      }
      this.progress = Math.round((translated / total) * 100)
    },
    processFileXLIFF(event) {
      console.log('processFileXLIFF', event)
      let file = event.target.files[0]
      if (file) {
        let self = this
        let reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = function() {
          let data = utils.parseXLIFF(reader.result)
          console.log('processed', data)
          let processedData = utils.createTiptapDocumentFromXLIFF(data)
          let originalDocument = processedData.document
          let comments = processedData.comments
          let targetDocument = utils.createTiptapDocumentFromXLIFF(data, 'target').document
          self.content = originalDocument.content
          self.versionContent = targetDocument
          self.comments = comments
          if (originalDocument.content.length > 0) {
            self.isEmpty = false
          }
          //to broadcast the new document:
          self.editor.commands.setContent(originalDocument)
          console.log('original', originalDocument, 'target', targetDocument, 'comments', comments)
          //comments will be created in a normal way (the user that uploads the document is the author of all the comments)
        }
        reader.onerror = function(evt) {
          console.error('error reading file', evt)
        }
      }
    },
    createDocumentFromXLIFF(data, type = 'source') {
      //TODO: Convert to tiptap document (original + target)
      let comments = []
      let document = {
        type: 'doc',
        content: []
      }
      for (let d = 0; d < data.length; d++) {
        //file
        if (data[d].notes) {
          //concat notes with comments
          comments.concat(data[d].notes)
        }
        for (let s = 0; s < data[d].segments.length; s++) {
          document.content.push({
            type: 'paragraph',
            attrs: {
              id: data[d].segments[s].unitId,
              groupId: data[d].segments[s].groupId ? data[d].segments[s].groupId : null
            },
            content: [
              {
                type: 'text',
                text: data[d].segments[s][type]
              }
            ]
          })
          //Add block notes
          comments.concat(
            data[d].segments[s].notes.map(note => {
              return {
                blockId: data[d].segments[s].unitId,
                content: note
              }
            })
          )
        }
      }
      return { document, comments }
    },
    getRandomElement(list) {
      return list[Math.floor(Math.random() * list.length)]
    },
    getRandomColor() {
      return this.getRandomElement(['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'])
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    recalculateOneBlockHeight(i, which) {
      if (!((which == 'original' && this.originalBlocksHeight[i]) || this.blocksHeight[i])) {
        console.log('recalculateOneBlockHeight', i, 'not found')
        return
      }
      console.log('recalculateOneBlockHeight', i)
      let currentBlockHeight = document.querySelectorAll('.content-block')[i].offsetHeight
      //let originalBlockHeight = document.querySelectorAll('.content-block-original')[i].offsetHeight
      let gapHeight = 2.25 * parseFloat(getComputedStyle(document.documentElement).fontSize)
      this.originalBlocksHeight[i] = currentBlockHeight + gapHeight
    },
    calculateMinBlockHeight() {
      console.log('recalculate ALL block heights')
      let currentContentBlocks = document.querySelectorAll('.content-block')
      let currentOriginalContentBlocks = document.querySelectorAll('.content-block-original')
      let newheights = []
      let gapHeight = 2.25 * parseFloat(getComputedStyle(document.documentElement).fontSize)
      currentContentBlocks.forEach((el, i) => {
        //compare both blocks and save the biggest height
        let originalBlock = currentOriginalContentBlocks[i]
        let height = Math.max(el.offsetHeight, originalBlock.offsetHeight)
        height += gapHeight
        newheights.push(height)
      })
      this.blocksHeight = newheights
      this.originalBlocksHeight = [...newheights]
    },
    loadPage(key) {
      ServicePages.getPage(key)
        .then(result => {
          console.log(result)
          this.page = result
          //let versionKey = this.page.versions.filter(el => el.key == this.versionKey)[0]
          //this.loadVersion(versionKey)
        })
        .catch(e => {
          console.error(e)
        })
    },
    openVersion(versionKey) {
      if (this.permissions?.write) {
        this.$router.push({
          name: 'content-pages-editor-with-version',
          params: { key: this.page.key, version: versionKey }
        })
      } else {
        this.$router.push({
          name: 'content-pages-viewer-with-version',
          params: { key: this.page.key, version: versionKey }
        })
      }
    },
    updateFile(blockIndex, fileKey) {
      console.log('updateFile', fileKey)
      this.dataChanged(blockIndex, null)
      this.$emit('file-updated', {
        blockIndex: blockIndex,
        fileKey: fileKey
      })
    },
    deleteFile(blockIndex, fileKey) {
      this.$buefy.dialog.confirm({
        title: this.$t('title_delete_file'),
        message: this.$t('delete_file'),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          console.log('deleteFile', fileKey)
          this.dataChanged(blockIndex, null)
          this.$emit('file-deleted', {
            blockIndex: blockIndex,
            fileKey: fileKey
          })
        }
      })
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file, null)
    },
    getThumbnail(file) {
      if (file) {
        return file.thumb_url_format
          .replace('{KEY}', file.key)
          .replace('{TOKEN}', file.token)
          .replace('{SIZE}', 'max2k')
          .replace('{FILENAME}', file.filename)
      } else {
        return ''
      }
    },
    uploadFile(i, event) {
      console.log('UPLOAD RESULT')
      console.log(event)
      if (event.length > 0) {
        let serverFile = event[0].response.data.file
        console.log('serverFile', serverFile)
        this.$emit(
          'file-uploaded',
          i /*{
          filename: serverFile.filename,
          key: serverFile.key,
          description: '',
        }*/,
          serverFile
        )
        this.$emit('mark-dirty', this.blocks[i].key)
      }
    },
    async removeInput(i) {
      let blocksnapshot = this.blocks[i]
      this.$buefy.dialog.confirm({
        title: this.$t('title_delete_content'),
        message: this.$t('delete_content'),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          this.$emit('remove-content', i)
          try {
            //let result =
            await ServicePages.deleteContentBlock(this.page.key, this.version.key, blocksnapshot.key)
            //console.log(result)
          } catch (e) {
            console.error(e)
            this.$emit('restore-block', i, blocksnapshot)
          }
        }
      })
    },
    getChildPayload(index) {
      return this.blocks[index]
    },
    dataChanged(blockIndex, event) {
      console.log('dataChanged, block: ' + blockIndex, event)
      if (this.blocks[blockIndex].content == null) {
        this.blocks[blockIndex].content = {}
      }
      if (this.blocks[blockIndex].type == 'text') {
        this.blocks[blockIndex]['content']['text'] = event
      } else if (this.blocks[blockIndex].type == 'image') {
        //this.blocks[blockIndex]['content']['text'] = this.blocks[blockIndex].content.text
      }
      this.$nextTick(() => {
        this.recalculateOneBlockHeight(blockIndex, 'original')
      })
      this.$emit('mark-dirty', this.blocks[blockIndex].key)
    },
    titleChanged() {
      this.$emit('mark-dirty', 'title')
    }
  }
}
</script>
<style lang="scss">
.titleeditor {
  @apply w-full text-3xl font-bold px-3;
}

.main-editor.rtl .main-text .ProseMirror p {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h1 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h2 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h3 {
  direction: rtl;
}

.main-editor.rtl .main-text .ProseMirror h4 {
  direction: rtl;
}

.main-text .ProseMirror {
  padding: 6px 8px;
  @apply focus:outline-none bg-white rounded-md;
}

.titleeditor .is-editor-empty:first-child:not(.active)::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.main-text .tiptap p:not(.active):first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #999;
  pointer-events: none;
  height: 0;
  white-space: nowrap !important;
}

.editor-menu {
  @apply flex gap-1 rounded-md py-0.5 px-1 border border-gray-200 shadow-sm;
  background-color: #fafafa;
  button {
    @apply border border-transparent bg-gray-50 rounded-md px-0.5 h-8 w-8 text-center flex items-center justify-center opacity-80;

    &:hover {
      @apply opacity-100;
    }

    &.is-active {
      @apply opacity-100 bg-gray-200 border-gray-300;
    }
  }
}

.main-text .tiptap .is-editor-empty .content {
  min-height: 120px;
}

.main-text .ProseMirror ul.editor-task-list {
  padding-left: 0rem;
}

.no-draggable-content .draggable-item .block-options {
  display: none;
}
.main-text .drag-handle {
  width: 1rem;
  height: 1.2rem;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-size: 0.7rem 0.7rem;
  cursor: grab;
  position: fixed;
  @apply shadow-sm bg-center bg-no-repeat bg-white rounded border border-gray-200 -ml-1.5 mt-1 opacity-0;
}

.main-text:hover .drag-handle {
  @apply opacity-100;
}

.draggable-item {
  @apply bg-white rounded-md min-h-7 relative pl-3 pr-3 py-0.5;

  .editor-drag-handle {
    width: 1rem;
    height: 1.2rem;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-size: 0.7rem 0.7rem;
    cursor: grab;
    @apply shadow-sm bg-center bg-no-repeat bg-white rounded border border-gray-200 ml-1;
  }
  .block-options {
    @apply absolute top-0.5 text-gray-800 transition-all opacity-0;
    gap: 0.07rem;
    left: -3.35rem;
  }
  .content {
    flex: 1 1 auto;
  }
}
.draggable-item:hover,
.draggable-item.active {
  .block-options {
    @apply opacity-100;
  }
  .editor-drag-handle {
    @apply cursor-move;
  }
}

.main-text ul.editor-task-list {
  @apply list-none;
}
.main-text ul.editor-task-list li {
  @apply list-none flex items-center;
}
.main-text ul.editor-task-list li input {
  @apply border border-gray-200 outline-none rounded-sm w-4 h-4 mr-1;
}
.main-text ul.editor-task-list li div {
  @apply flex-1;
}

.main-text .image-uploader .file-uploads {
  @apply py-10 w-full;
}
.main-text .change-image-uploader .file-uploads {
  @apply py-2 w-full;
}
.main-text .content-block .editor-drag-handle {
  @apply transition-all opacity-0;
}
.main-text .content-block:hover .editor-drag-handle {
  @apply transition-all opacity-100;
}
.main-text .content-block .add-content {
  @apply transition-all opacity-0;
}
.main-text .content-block:hover .add-content {
  @apply transition-all opacity-100;
}
.main-text .content-block-container {
  min-height: 15px;
  @apply relative;
}
.main-text .image-uploader label {
  z-index: 0 !important;
}
/* Give a remote user a caret */
.main-text .collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.main-text .collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

/* Table-specific styling */

.main-text .tableWrapper {
  margin: 1.5rem 0;
  // overflow-x: auto;
}

.main-text .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.main-editor ol {
  @apply list-decimal relative;
}
.main-editor ol li::marker {
  @apply text-sm font-medium absolute top-0.5 left-0;
}

.ProseMirror .more-options a {
  @apply text-gray-600;
}

.titleeditor {
  @apply w-full text-2xl font-bold px-3;
}

.titleeditor .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.editor-menu {
  @apply flex gap-1 rounded-md py-0.5 px-1 border border-gray-200 shadow-sm;
  background-color: #fafafa;

  button {
    @apply border border-transparent bg-gray-50 rounded-md px-0.5 h-8 w-8 text-center flex items-center justify-center opacity-80;

    &:hover {
      @apply opacity-100;
    }

    &.is-active {
      @apply opacity-100 bg-gray-200 border-gray-300;
    }
  }
}

.tiptap code {
  @apply bg-gray-100 px-1 mx-1 text-sm py-0.5 rounded-md border border-gray-200 font-mono;
}

.tiptap .is-editor-empty .content {
  min-height: 120px;
}

.tiptap div.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror ul.editor-task-list {
  padding-left: 0rem;
}

.no-draggable-content .draggable-item .block-options {
  display: none;
}

.draggable-item {
  @apply bg-white rounded-md min-h-7 relative px-3 py-0.5;

  .editor-drag-handle {
    width: 1rem;
    height: 1.2rem;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-size: 0.7rem 0.7rem;
    cursor: grab;
    @apply shadow-sm bg-center bg-no-repeat bg-white rounded border border-gray-200 ml-1;
  }

  .block-options {
    @apply absolute top-0.5 text-gray-800 transition-all opacity-0;
    gap: 0.07rem;
    left: -3.35rem;
  }

  .content {
    flex: 1 1 auto;
  }
}

.draggable-item:hover,
.draggable-item.active {
  .block-options {
    @apply opacity-100;
  }

  .editor-drag-handle {
    @apply cursor-move;
  }
}

ul.editor-task-list {
  @apply list-none;
}

ul.editor-task-list li {
  @apply list-none flex items-center;
}

ul.editor-task-list li input {
  @apply border border-gray-200 outline-none rounded-sm w-4 h-4 mr-1;
}

ul.editor-task-list li div {
  @apply flex-1;
}

table.editor-table {
  @apply w-full border-collapse;
}

table.editor-table th,
table.editor-table td {
  @apply border border-gray-200;
}

table.editor-table td {
  @apply w-1/2;
}

.image-uploader .file-uploads {
  @apply py-10 w-full;
}

.change-image-uploader .file-uploads {
  @apply py-2 w-full;
}

.content-block .editor-drag-handle {
  @apply transition-all opacity-0;
}

.content-block:hover .editor-drag-handle {
  @apply transition-all opacity-100;
}

.content-block .add-content {
  @apply transition-all opacity-0;
}

.content-block:hover .add-content {
  @apply transition-all opacity-100;
}

.content-block-container {
  min-height: 15px;
  @apply relative;
}

.image-uploader label {
  z-index: 0 !important;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}
</style>

<i18n>
  {
    "pt": {
      "edit": "Editar",
      "in_edition": "em edição",
      "progress": "Progresso",
      "title_delete_file": "Apagar ficheiro",
      "title_delete_content": "Apagar conteúdo",
      "delete_content": "Tem a certeza que deseja <b>eliminar</b> este conteúdo? Esta ação não pode ser desfeita.",
      "delete_file": "Tem a certeza que deseja <b>eliminar</b> este ficheiro? Esta ação não pode ser desfeita.",
      "delete": "Apagar"
    },
    "en": {
      "in_edition": "in edition",
      "progress": "Progress",
      "title_delete_file": "Delete file",
      "title_delete_content": "Delete content",
      "delete_content": "Are you sure you want to <b>delete</b> this content? This action cannot be undone.",
      "delete_file": "Are you sure you want to <b>delete</b> this file? This action cannot be undone.",
      "delete": "Delete",
      "edit": "Edit"
    }
  }
</i18n>
