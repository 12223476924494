<template>
  <div
    class="bg-gray-200 rounded flex items-center justify-center"
    :class="{
      'w-5 h-5': size == 'md',
      'w-4 h-4': size == 'sm',
      'w-6 h-6': size == 'lg'
    }"
  >
    <fw-icon-paragraph
      v-if="type == 'paragraph'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-heading-1
      v-else-if="type == 'heading-1'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-heading-2
      v-else-if="type == 'heading-2'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-heading-3
      v-else-if="type == 'heading-3'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-image
      v-else-if="type == 'image'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-video
      v-else-if="type == 'video' || type == 'youtube'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-code
      v-else-if="type == 'codeBlock'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-quotes
      v-else-if="type == 'blockquote'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-unorderedlist
      v-else-if="type === 'bulletList'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-orderedlist
      v-else-if="type === 'orderedList'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-list-check
      v-else-if="type === 'taskList'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-table
      v-else-if="type === 'table'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-5 h-5': size == 'lg'
      }"
    />
    <fw-icon-file
      v-else-if="type === 'document'"
      :class="{
        'w-3 h-3': size == 'sm',
        'w-4 h-4': size == 'md',
        'w-4 h-4': size == 'lg'
      }"
    />
  </div>
</template>
<script>
export default {
  name: 'BlockIcon',
  props: {
    type: {
      type: String,
      default: 'paragraph'
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>
