var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray-200 rounded flex items-center justify-center",class:{
    'w-5 h-5': _vm.size == 'md',
    'w-4 h-4': _vm.size == 'sm',
    'w-6 h-6': _vm.size == 'lg'
  }},[(_vm.type == 'paragraph')?_c('fw-icon-paragraph',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'heading-1')?_c('fw-icon-heading-1',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'heading-2')?_c('fw-icon-heading-2',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'heading-3')?_c('fw-icon-heading-3',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'image')?_c('fw-icon-image',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'video' || _vm.type == 'youtube')?_c('fw-icon-video',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'codeBlock')?_c('fw-icon-code',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type == 'blockquote')?_c('fw-icon-quotes',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type === 'bulletList')?_c('fw-icon-unorderedlist',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type === 'orderedList')?_c('fw-icon-orderedlist',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type === 'taskList')?_c('fw-icon-list-check',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type === 'table')?_c('fw-icon-table',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-5 h-5': _vm.size == 'lg'
    }}):(_vm.type === 'document')?_c('fw-icon-file',{class:{
      'w-3 h-3': _vm.size == 'sm',
      'w-4 h-4': _vm.size == 'md',
      'w-4 h-4': _vm.size == 'lg'
    }}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }